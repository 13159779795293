import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Card, CardHeader, CardContent, Divider, FormHelperText, FormControl, MenuItem, Select, IconButton
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslate } from 'react-admin';
import { BACKEND_URL } from '../../constants';
  
export default function Appointment() {
    const translate = useTranslate()
    const [chartData, setChartData] = useState<any>({'labels': [], 'datasets': []})
    const [periods, setPeriods] = useState("next_7_days")
    const [refresh, setRefresh] = useState(true)
    const chartOptions: any = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'right' // place legend on the right side of chart
      },
      scales: {
        x: {
          stacked: false,
          barThickness: 20,
          //  maxBarThickness: 30,
          // barPercentage: 0.5, // this should be set to make the bars stacked
        },
        y: {
          stacked: false, // this also..
          ticks: {
            beginAtZero: true
          },
        }
      }
    };

    useEffect( () => {
        fetch(`${BACKEND_URL}/dashboard/appointment-by-channel?period=${periods}`)
          .then((response) => response.json())
          .then((data) => {
            setChartData(data);
          });       
    }, [periods, refresh])

    return (
        <Card>
          <CardHeader
              action={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl>
                      <Select value={periods} onChange={ (e) => setPeriods(e.target.value) } label="Periods"
                        sx= {{ 
                          '& .MuiInputBase-input': {
                              backgroundColor: 'white', // Set the background color to white
                          }
                        }}>
                        <MenuItem value="last_7_days">Last 7 days</MenuItem>
                        <MenuItem value="last_14_days">Last 14 days</MenuItem>
                        <MenuItem value="last_30_days">Last 30 days</MenuItem>
                        <MenuItem value="next_7_days">Next 7 days</MenuItem>
                        <MenuItem value="next_14_days">Next 14 days</MenuItem>
                        <MenuItem value="next_30_days">Next 30 days</MenuItem>
                      </Select>
                      <FormHelperText>Periods</FormHelperText>
                  </FormControl>
                  <IconButton size="small">
                    <RefreshIcon onClick={ e => setRefresh(!refresh) } />
                  </IconButton>
                </div>
              }
              title={translate("resources.dashboard.title.appointment_by_channel")}
          />
          <Divider />
          <CardContent>
              <div style={{ height: 400, position: 'relative' }}>
              <Bar
                  data={chartData}
                  options={chartOptions}
              />
              </div>
          </CardContent>
        </Card>
    );
};
