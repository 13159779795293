import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Card, CardHeader, CardContent, Divider, FormHelperText, FormControl, MenuItem, Select, IconButton
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh'
import { useTranslate } from 'react-admin'
import { BACKEND_URL } from '../../constants'

const backgroundColor = [
  "rgba( 123, 36, 28 ,0.5)","rgba( 176, 58, 46 ,0.5)","rgba( 176, 58, 46 ,0.5)","rgba( 108, 52, 131 ,0.5)","rgba( 31, 97, 141 ,0.5)","rgba( 40, 116, 166 ,0.5)","rgba( 23, 165, 137 ,0.5)","rgba( 34, 153, 84 ,0.5)","rgba( 241, 196, 15 ,0.5)","rgba( 243, 156, 18 ,0.5)",
  "rgba( 229, 152, 102 ,0.5)","rgba( 237, 187, 153 ,0.5)","rgba( 246, 221, 204 ,0.5)","rgba(248,196,113,0.5)","rgba( 242, 243, 244 ,0.5)"
]
const borderColor = [
  "rgba( 123, 36, 28 ,1)","rgba( 176, 58, 46 ,1)","rgba( 176, 58, 46 ,1)","rgba( 108, 52, 131 ,1)","rgba( 31, 97, 141 ,1)","rgba( 40, 116, 166 ,1)","rgba( 23, 165, 137 ,1)","rgba( 34, 153, 84 ,1)","rgba( 241, 196, 15 ,1)","rgba( 243, 156, 18 ,1)",
  "rgba( 229, 152, 102 ,1)","rgba( 237, 187, 153 ,1)","rgba( 246, 221, 204 ,1)","rgba(248,196,113,1)","rgba( 242, 243, 244 ,1)"
]
export default function TopAppointmentPackage(){
    const translate = useTranslate()
    const [chartData, setChartData] = useState<any>({'labels': [], 'datasets': []})
    const [refresh, setRefresh] = useState(true)
    const [max, setMax] = useState<string | number>(5)

    const chartOptions: any = {
      indexAxis: 'y',
      // Elements options apply to all of the options unless overridden in a dataset
      // In this case, we are setting the border of each horizontal bar to be 2px wide
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      // plugins: {
      //   legend: {
      //     position: 'right',
      //   },
      //   // title: {
      //   //   display: true,
      //   //   text: translate('pos.dashboard.title.top_intent'),
      //   // },
      // },
    }

    useEffect( () => {
      fetch(`${BACKEND_URL}/dashboard/top-appointment-package?max=${max}`)
        .then((response) => response.json())
        .then((data) => {
          setChartData({
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                label: translate('resources.dashboard.legend.top_appointment_packages'),
                backgroundColor: backgroundColor.slice(0,data.data.length),
                borderColor: borderColor.slice(0,data.data.length),
                borderWidth: 1,
              }
            ]
          })
        })         
    }, [max, refresh])

    return (
        <Card>
          <CardHeader
              action={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                 <FormControl>
                      <Select value={max} onChange={ (e) => setMax(e.target.value) }
                        sx= {{ 
                          '& .MuiInputBase-input': {
                              backgroundColor: 'white', // Set the background color to white
                          }
                        }}>
                        <MenuItem value={5}>Top 5</MenuItem>
                        <MenuItem value={10}>Top 10</MenuItem>
                        <MenuItem value={15}>Top 15</MenuItem>
                      </Select>
                      <FormHelperText>Packages</FormHelperText>
                  </FormControl>
                  <IconButton size="small">
                    <RefreshIcon onClick={ e => setRefresh(!refresh) } />
                  </IconButton>
                </div>
              }
              title={translate("resources.dashboard.title.top_appointment_packages")}
          />
          <Divider />
          <CardContent>
              <div style={{ height: 400, position: 'relative' }}>
              <Bar
                  data={chartData}
                  options={chartOptions}
              />
              </div>
          </CardContent>
        </Card>
    );
};