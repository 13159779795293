import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Pie } from 'react-chartjs-2';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import palette from './palette'
import {useTranslate} from 'react-admin'
import { BACKEND_URL } from '../../constants'

export default function Conversation(){
  const theme = useTheme();
  const translate = useTranslate()
  const whatsappColor = "#7dd666"
  const facebookColor = "#1b74e4"
  const webchatColor =  "#4ab4fe"

  const chartOptions = {
    legend: {
      display: true
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    // cutoutPercentage: 50,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary
    }
  };

  const [channels, setChannels] = useState<any>([ ,])
  const [chartData, setChartData] = useState<any>({'labels': [], 'datasets': []})
  const [refresh, setRefresh] = useState(true)

  useEffect( () => {
    fetch(`${BACKEND_URL}/dashboard/conversation-volume`)
      .then((response) => response.json())
      .then((data) => {
        setChannels([
          {
            title: 'WhatsApp',
            value: data.wa,
            color: whatsappColor
          },
          {
            title: 'Facebook',
            value: data.fb,
            color: facebookColor
          },
          {
            title: 'WebChat',
            value: data.socketio,
            color: webchatColor   
          }
        ])
        setChartData({
          datasets: [
            {
              data: [data.wa, data.fb, data.socketio],
              backgroundColor: [
                whatsappColor, facebookColor, webchatColor
              ],
              borderWidth: 8,
              borderColor: palette.white,
              hoverBorderColor: palette.white
            }
          ],
          labels: ['WhatsApp', 'Facebook', 'WebChat']
        })
      })         
  }, [refresh])

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        action={
          <IconButton size="small">
            <RefreshIcon onClick={e => setRefresh(!refresh)}/>
          </IconButton>
        }
        title={translate("resources.dashboard.title.conversation_volumes")}
      />
      <Divider />
      <CardContent>
        <div style={{ position: 'relative', height: '300px'}}>
          <Pie
            data={chartData}
            options={chartOptions}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', padding: theme.spacing(2) }}>
          {channels.map((device: any) => (
            <div
              style={{ textAlign: 'center', padding: theme.spacing(1) }}
              key={device.title}
            >
              <span style={{ color: palette.icon }}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography
                style={{ color: device.color }}
                variant="h4"
              >
                {device.value}
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};