import { useState, useEffect }  from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Paper, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Box } from '@mui/material';
import { VictoryPie } from "victory";
import { BACKEND_URL } from '../../constants'
import { Doughnut } from 'react-chartjs-2';


export default function OnlineEnquiry({selectedStartDate, selectedEndDate}: any) {
  const [basis, setBasis] = useState('daily');
  const [whatsappCount, setWhatsappCount] = useState(0);
  const [facebookCount, setFacebookCount] = useState(0);
  const [webchatCount, setWebchatCount] = useState(0);
  const [chartData, setChartData] = useState({
    labels: ['WhatsApp','Facebook','Webchat'],
    datasets: [
      {
        // labels: [
        //   'WhatsApp:',
        //   'Facebook:',
        //   'WebChat:',
        // ],
        data: [
          whatsappCount, facebookCount, webchatCount
        ],
        backgroundColor: [
          '#25D366',
          '#1877F2',
          '#2E7D32',
        ],
        borderColor: [
          'white',
          'white',
          'white',
        ],
        borderWidth: 2,
      },
    ],
  });

  const handleChangeBasis = (event: any) => {
    setBasis(event.target.value);
  };

  const options = {
    cutout: 70, // set the cutoutPercentage to 70 to create a custom inner radius
    plugins: {
      legend: {
        display: false,  // Disable Legend for the Doughnut chart
      }
    }
  };
  
  const fetchCountFromBackend = async () => {
    const apiUrl = `${BACKEND_URL}/get-online-enquiry?start_date=${selectedStartDate.format('YYYY-MM-DD')}&end_date=${selectedEndDate.format('YYYY-MM-DD')}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setWhatsappCount(data.whatsappCount)
        setFacebookCount(data.facebookCount)
        setWebchatCount(data.webchatCount)
        const newData = {
          labels: ['WhatsApp','Facebook','Webchat'],
          datasets: [
            {
              data: [
                data.whatsappCount, data.facebookCount, data.webchatCount
              ],
              backgroundColor: [
                '#25D366',
                '#1877F2',
                '#2E7D32',
              ],
              borderColor: [
                'white',
                'white',
                'white',
              ],
              borderWidth: 2,
            },
          ],
        };
        setChartData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    fetchCountFromBackend();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <Paper sx={{backgroundColor: 'white', boarderRadius: '12px', height: '100%'}}>
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between'}}>
        <Stack alignItems='center' direction="row">
          <Typography sx={{ m: 1, fontFamily: 'Roboto', fontWeight: 'bold' }} variant="body2">Online Enquiries</Typography>
        </Stack>
      </Box>
      <br/>
      <br/>
      <Stack flexDirection="row" justifyContent="center">
        <Box width={200} height={200}>
          <Doughnut data={chartData} options={options}/>
        </Box>
      </Stack>
      <br/>
      <br/>
      <Stack sx={{ m:2 }} divider={<Divider variant="middle" orientation="horizontal" flexItem />} alignItems="center" spacing={1} direction="column">
        <Stack spacing={10} justifyContent="space-between" direction="row">
          <Stack spacing={2} alignItems="center" direction="row">
            <Badge variant="dot">
              <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#25D366' }} />
            </Badge>  
            <Typography variant="body1">WhatsApp</Typography>
          </Stack>
          <Typography variant="body1">{whatsappCount}</Typography>
        </Stack>
        <Stack spacing={10} justifyContent="space-between"  direction="row">
          <Stack spacing={2} alignItems="center" direction="row">
            <Badge variant="dot">
              <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#1877F2' }} />
            </Badge>  
            <Typography variant="body1">Facebook</Typography>
          </Stack> 
          <Typography variant="body1">{facebookCount}</Typography>
        </Stack>
        <Stack spacing={10} justifyContent="space-between"  direction="row">
          <Stack spacing={2} alignItems="center" direction="row">
              <Badge variant="dot">
                <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#2E7D32' }} />
              </Badge>  
              <Typography variant="body1">WebChat</Typography>
          </Stack>
          <Typography variant="body1" >{webchatCount}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
