import { useState, useEffect }  from 'react';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import { Paper, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Box } from '@mui/material';
import { BACKEND_URL } from '../../constants'
import { Doughnut } from 'react-chartjs-2';

export default function SuccessfulResponse({selectedStartDate, selectedEndDate}: any) {
  const [basis, setBasis] = useState('daily');
  const [predictedCount, setPredictedCount] = useState(0);
  const [fallbackCount, setFallbackCount] = useState(0);
  const [predictedCountPercentage, setPredictedCountPercentage] = useState(0);
  const [fallbackCountPercentage, setFallbackCountPercentage] = useState(0);

  const [chartData, setChartData] = useState({
    labels: ['Predicted','Fallback'],
    datasets: [
      {
        data: [
          predictedCount, fallbackCount
        ],
        backgroundColor: [
          '#1976d2',
          '#bbdefb',
        ],
        borderColor: [
          'white',
          'white',
        ],
        borderWidth: 2,
      },
    ],
  });
  
  const handleChangeBasis = (event: any) => {
    setBasis(event.target.value);
  };

  const fetchCountFromBackend = async () => {
    const apiUrl = `${BACKEND_URL}/get-successful-response?start_date=${selectedStartDate.format('YYYY-MM-DD')}&end_date=${selectedEndDate.format('YYYY-MM-DD')}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const newData = {
          labels: ['Predicted','Fallback'],
          datasets: [
            {
              data: [
                data.predictedCount, data.fallbackCount
              ],
              backgroundColor: [
                '#1976d2',
                '#bbdefb',
              ],
              borderColor: [
                'white',
                'white',
              ],
              borderWidth: 2,
            },
          ],
        };
        setChartData(newData);
        setPredictedCount(data.predictedCount)
        setFallbackCount(data.fallbackCount)
        setPredictedCountPercentage(data.predictedPercentage)
        setFallbackCountPercentage(data.fallbackPercentage)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    fetchCountFromBackend();
  }, [selectedStartDate, selectedEndDate]);

  const options = {
    cutout: 70, // set the cutoutPercentage to 70 to create a custom inner radius
    plugins: {
      legend: {
        display: false,  // Disable Legend for the Doughnut chart
      }
    }
  };
  
  return (
      <Paper sx={{backgroundColor: 'white', boarderRadius: '12px', height: '100%'}}>
        <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between'}}>
          <Stack alignItems='center' direction="row">
            <Typography sx={{ m: 1, fontFamily: 'Roboto', fontWeight: 'bold' }} variant="body2">Predictions</Typography>
          </Stack>
        </Box>
        <br/>
        <br/>
        <Stack flexDirection="row" justifyContent="center">
          <Box width={200} height={200}>
            <Doughnut data={chartData} options={options}/>
          </Box>
        </Stack>
        <br/>
        <br/>
        <Stack sx={{ m:2 }} divider={<Divider variant="middle" orientation="horizontal" flexItem />} alignItems="center" spacing={1} direction="column">
        <Stack spacing={10} justifyContent="space-between" direction="row">
          <Stack spacing={2} alignItems="center" direction="row">
            <Badge variant="dot">
              <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#1976d2' }} />
            </Badge>  
            <Typography variant="body1">Predicted</Typography>
          </Stack>
          <Typography variant="body1">{predictedCountPercentage}%</Typography>
        </Stack>
        <Stack spacing={10} justifyContent="space-between"  direction="row">
          <Stack spacing={2} alignItems="center" direction="row">
            <Badge variant="dot">
              <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#bbdefb' }} />
            </Badge>  
            <Typography variant="body1">Fallback</Typography>
          </Stack> 
          <Typography variant="body1">{fallbackCountPercentage}%</Typography>
        </Stack>
      </Stack>


        {/* <Stack justifyContent="center" spacing={5} direction="row">
          <Stack spacing={1} direction="column">
            <Typography variant="body1">Predicted</Typography>
            <Typography variant="body1" sx={{color: '#e57373'}}>{predictedCount}%</Typography>
          </Stack>
          <Stack spacing={1} direction="column">
            <Typography variant="body1">Fallback</Typography>
            <Typography variant="body1" sx={{color: '#e53935'}}>{fallbackCount}%</Typography>
          </Stack>
        </Stack> */}
      </Paper>
  );
};
