import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Card, CardHeader, CardContent, Divider, FormHelperText, FormControl, MenuItem, Select, IconButton
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslate } from 'react-admin'
import { BACKEND_URL } from '../../constants'

const backgroundColor = [
  "rgba(36,113,163,0.2)","rgba(41,128,185,0.2)","rgba(52,152,219,0.2)","rgba(93,173,226,0.2)","rgba(231,76,60,0.2)","rgba(236,112,99,0.2)","rgba(175,122,197,0.2)","rgba(187,143,206,0.2)","rgba(187,143,206,0.2)","rgba(210,180,222,0.2)",
  "rgba(230,126,34,0.2)","rgba(229,152,102,0.2)","rgba(235,152,78,0.2)","rgba(248,196,113,0.2)","rgba(130,224,170,0.2)","rgba(171,235,198,0.2)","rgba(178,186,187,0.2)","rgba(204,209,209,0.2)","rgba(215,219,221,0.2)","rgba(242,243,244,0.2)"
]
const borderColor = [
  "rgba(36,113,163,1)","rgba(41,128,185,1)","rgba(52,152,219,1)","rgba(93,173,226,1)","rgba(231,76,60,1)","rgba(236,112,99,1)","rgba(175,122,197,1)","rgba(187,143,206,1)","rgba(187,143,206,1)","rgba(210,180,222,1)",
  "rgba(230,126,34,1)","rgba(229,152,102,1)","rgba(235,152,78,1)","rgba(248,196,113,1)","rgba(130,224,170,1)","rgba(171,235,198,1)","rgba(178,186,187,1)","rgba(204,209,209,1)","rgba(215,219,221,1)","rgba(242,243,244,1)"
]

export default function TopIntent(){
    const translate = useTranslate()
    const [chartData, setChartData] = useState<any>({'labels': [], 'datasets': []})
    const [periods, setPeriods] = useState("next_7_days")
    const [refresh, setRefresh] = useState(true)
    const [max, setMax] = useState<string | number>(5)

    const chartOptions: any = {
      indexAxis: 'y',
      // Elements options apply to all of the options unless overridden in a dataset
      // In this case, we are setting the border of each horizontal bar to be 2px wide
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      // plugins: {
      //   legend: {
      //     position: 'right',
      //   },
      //   // title: {
      //   //   display: true,
      //   //   text: translate('pos.dashboard.title.top_intent'),
      //   // },
      // },
    }

    useEffect( () => {
      const apiUrl = `${BACKEND_URL}/dashboard/top-intents?max=${max}`;
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          setChartData({
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                label: translate('resources.dashboard.legend.top_intents'),
                backgroundColor: backgroundColor.slice(0,data.data.length),
                borderColor: borderColor.slice(0,data.data.length),
                borderWidth: 1,
              }
            ]
          })
        })
        .catch((error) => {
          console.log(error);
        });        
    }, [max, refresh])

    return (
        <Card>
          <CardHeader
              action={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                 <FormControl>
                      <Select value={max} onChange={ (e) => setMax(e.target.value) }
                        sx= {{ 
                          '& .MuiInputBase-input': {
                              backgroundColor: 'white', // Set the background color to white
                          }
                        }}>
                        <MenuItem value={5}>Top 5</MenuItem>
                        <MenuItem value={10}>Top 10</MenuItem>
                        <MenuItem value={15}>Top 15</MenuItem>
                      </Select>
                      <FormHelperText>Intents</FormHelperText>
                  </FormControl>
                  <IconButton size="small">
                    <RefreshIcon onClick={ e => setRefresh(!refresh) } />
                  </IconButton>
                </div>
              }
              title={translate("resources.dashboard.title.top_intents")}
          />
          <Divider />
          <CardContent>
              <div style={{ height: 400, position: 'relative' }}>
              <Bar
                  data={chartData}
                  options={chartOptions}
              />
              </div>
          </CardContent>
        </Card>
    );
};